<template>
  <div id="TicketingHomepage">
    <qs-kpi-grid
      title="Votre activité récente"
      :qlik-ids="['aPLkdAQ', 'MLxWVH', 'vxfAJ', 'zNsm', 'PtbyG', 'XVKSbT']"
      tooltip="Quelques indicateurs clef de votre activité récente, comparés à la même période de l'année précédente."
    />

    <qs-kpi-grid
      title="Vos indicateurs"
      tooltip="Cumul depuis la première valeur en base"
      :qlik-ids="['KTaFf', 'YXxdPC', 'QRNejcq', 'xEJqgQR', 'hUek', 'JwQMgaU']"
      :preserves-on-destroy="['QRNejcq']"
    />

    <QsTabs
      :qlik-ids="['JqHTVpW', 'bBChmB', 'VFKuMyZ']"
      style="grid-column: 1 / span 2"
    />
  </div>
</template>

<script>
import QsKpiGrid from "@/components/Qlik/QsKpiGrid";
import QsTabs from "@/components/Qlik/QsTabs";

export default { components: { QsKpiGrid, QsTabs } };
</script>

<style scoped>
#TicketingHomepage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 450px 400px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
